import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../../components/IT/layout'
import Banner from '../../components/IT/Banner'

import pic01 from '../../assets/imgs/landing/pic1.jpg'
import pic02 from '../../assets/imgs/landing/pic2.jpg'
import pic03 from '../../assets/imgs/landing/pic3.jpg'
import pic04 from '../../assets/imgs/landing/pic4.jpg'
import pic05 from '../../assets/imgs/landing/pic5.jpg'
import lor1 from '../../assets/images/lor11.jpg'
import unefs from '../../assets/imgs/unefs.png'
import tango from '../../assets/imgs/tango.jpg'
import csen from '../../assets/imgs/csen.png'
import alba from '../../assets/imgs/alba.png'
import act from '../../assets/imgs/act.png'
import asdb from '../../assets/imgs/ASDB.jpg'
import ce_a_butterfly from '../../assets/imgs/ceabutterfly.jpg'
import download from '../../assets/imgs/download.jpg'
import hoffnung from '../../assets/imgs/hoffnung.jpg'
import manadelucru from '../../assets/imgs/manadelucru.png'
class HomeIndex extends React.Component {

    constructor() {
        super();
        this.state = {
            lang : "ro"
        }
    }
    state = {
        lang: "ro"
    }
    changeLang =  event =>{
        this.setState({
          [event.target.name] : event.target.value
        })

        console.log(this.state.lang);
      }
    render() {

        return (
            <Layout>
                <Helmet
                    title="Unidans Project"
                    meta={[
                        { name: 'description', content: 'Sample' },
                        { name: 'keywords', content: 'sample, something' },
                    ]}
                >
                </Helmet>

                <Banner />
                
                <div id="main">
                    {/* <ul style = {{zIndex: "1", marginTop: "10px", marginBottom: "10px", textAlign: "center",width: "50%", margin: "auto"}}className="features"> 
                            <button><Link to="/objectives" className="link primary">aaa</Link></button>
                            <button className = {this.state.lang === "en" ? "active" : "nonActive"} name = "lang" value = "en" onClick = {this.changeLang}> English </button>
                            <button className = {this.state.lang === "fr" ? "active" : "nonActive"} name = "lang" value = "fr" onClick = {this.changeLang}> Francais </button>
                    </ul> */}
                    <section id="one" className="tiles">
                        <article style={{backgroundImage: `url(${pic01})`}}>
                            <header className="major">
                                <h3> Il Progetto </h3>
                                <p>Tutto ciò che devi sapere</p>
                            </header>
                            <Link to="/IT/landing" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic02})`}}>
                            <header className="major">
                                <h3> Partners </h3>
                                <p>Professionisti del settore</p>
                            </header>
                            <Link to="/IT/partners" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic03})`}}>
                            <header className="major">
                                <h3> Eventi </h3>
                                <p> I nostri eventi passati e futuri </p>
                            </header>
                            <Link to="/IT/events" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic04})`}}>
                            <header className="major">
                                <h3> News </h3>
                                <p> Le nostre ultime notizie </p>
                            </header>
                            <Link to="/IT/blog" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic05})`}}>
                            <header className="major">
                                <h3> Risultati </h3>
                                <p> Quantificare le azioni </p>
                            </header>
                            <Link to="/IT/results" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${lor1})`}}>
                            <header className="major">
                                <h3> Contatti </h3>
                                <p> Mettiti in contatto con noi </p>
                            </header>
                            <Link to="/IT/blog" className="link primary"></Link>
                        </article>
                        
                    </section>
                    <section id="two">
                        <div className="inner">
                            <header className="major">
                                <h2> Partner istituzionali </h2>
                            </header>
                            <a href = "http://unefsb.ro"><img src= {unefs}/></a>
                            <a href = "http://tangoact.ro"><img style= {{ marginLeft: "20px", width: "250px", height: "auto"}}src= {tango}/></a>
                        <br/><br/>
                        <a href = "http://www.asdb.ro/"> <img style = {{height:'250px', width: 'auto', marginRight: '10px'}} src={asdb} /></a>
                        <a href = "https://aalba.cat/ca/c/club-esportiu-alba-56"> <img style = {{height:'250px', width: 'auto', marginRight: '10px'}} src={ce_a_butterfly} /></a>
                        <a href = "https://sindromdownplus.com/contact/"> <img style = {{height:'250px', width: 'auto', marginRight: '10px'}} src={download} /></a>
                        <a href = "https://ro-ro.facebook.com/pages/category/Non-Governmental-Organization--NGO-/Hoffnung-Romania-494149993990060/"> <img style = {{height:'250px', width: 'auto', marginRight: '10px'}} src={hoffnung} /></a>
                        <a href = "https://www.facebook.com/fundatiamanadelucru/"> <img style = {{height:'250px', width: 'auto', marginRight: '10px'}} src={manadelucru} /></a>

                            {/* <ul className="actions">
                                <li><Link to="/landing" className="button next">Get Started</Link></li>
                            </ul> */}
                        </div>
                    </section>
                </div>

            </Layout>
        )
    }
}

export default HomeIndex